//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import '@/components/icons/how-it-works';
import { preFetchPage } from '@/helpers/router';
import Base from '@/mixins/Base';
import HowItWorkText from '@/components/HowItWorks/HowItWorkText';
export default {
  name: 'PageHowItWorks',
  mixins: [Base],
  components: {
    'app-how-it-works-text': HowItWorkText
  },
  props: {
    config: Object
  },
  preFetch: preFetchPage,
  meta: function meta() {
    var _this$seoData, _this$seoData2, _this$seoData3;

    return {
      title: ((_this$seoData = this.seoData) === null || _this$seoData === void 0 ? void 0 : _this$seoData.seoTitle) || 'PiasterCreek',
      meta: {
        description: {
          name: 'description',
          content: (_this$seoData2 = this.seoData) === null || _this$seoData2 === void 0 ? void 0 : _this$seoData2.seoDescription
        },
        keywords: {
          name: 'keywords',
          content: ((_this$seoData3 = this.seoData) === null || _this$seoData3 === void 0 ? void 0 : _this$seoData3.seoKeywords) || ''
        }
      }
    };
  },
  computed: {
    sections: function sections() {
      return this.$store.getters['sidebar/pageSections'];
    },
    seoData: function seoData() {
      return this.$store.getters['statics/seoData'];
    },
    loggedIn: function loggedIn() {
      return this.$store.getters['player/loggedIn'];
    }
  },
  methods: {
    openAuth: function openAuth() {
      this.$store.dispatch('modals/setAuthModal', {
        isOpen: true
      });
    },
    scrollToContent: function scrollToContent() {
      var howItWorkBlock = document.querySelector('.how-it');
      if (!howItWorkBlock) return;
      window.scrollTo({
        top: howItWorkBlock.getBoundingClientRect().y,
        behavior: 'smooth'
      });
    },
    handleClick: function handleClick() {
      if (this.loggedIn) this.scrollToContent();else this.openAuth();
    }
  }
};